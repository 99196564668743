import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import PageTitle from '../components/pagetitle/PageTitle';
import data from '../assets/fake-data/data-blog';
import { bloguser } from '../utils/Assets';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import { WebsiteUrl } from '../App';
import { truncate } from 'lodash';
import '../css/Blog02.css';
Blog02.propTypes = {};

function Blog02(props) {
  const [data, setdata] = useState([]);
  const [filteredData, setfilteredData] = useState([]);
  const [data2, setdata2] = useState([]);
  const [data3, setdata3] = useState([]);
  const location = useLocation();


  const [categoryChecker, setcategoryChecker] = useState('');

  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true); 

  const postsPerPage = 3;

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  // const currentPosts = data.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant'
    });
  }, [location.pathname]);

  useEffect(() => {
    setLoading(true);

    axios
      .get(`${global.backendUrl}/blog/get`)
      .then((response) => {
        setdata(response.data);
        setfilteredData(response.data);
    setLoading(false);

      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${global.backendUrl}/homeBlogSco/get`)
      .then((response) => {
        setdata2(response.data);
    setLoading(false);

      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${global.backendUrl}/blogCategory/get`)
      .then((response) => {
        setdata3(response.data);
    setLoading(false);

      })
      .catch((err) => {
        console.log(err);
      });
      
  }, []);

  const [dataRecent] = useState([
    {
      id: 1,
      img: bloguser,
      title: 'Best Corporate Tips You Will Read This Year.',
      cate: 'Music NFT’s',
    },
    {
      id: 2,
      img: bloguser,
      title: 'Should Fixing Corporate Take 100 Steps.',
      cate: 'Music NFT’s',
    },
    {
      id: 3,
      img: bloguser,
      title: 'The Next 100 Things To Immediately Do About',
      cate: 'Music NFT’s',
    },
    {
      id: 4,
      img: bloguser,
      title: 'Top 5 Lessons About Corporate To Learn',
      cate: 'Music NFT’s',
    },
  ]);

  // const [showAll, setShowAll] = useState(false);
  // const initialCount = 3;

  // const toggleShowAll = () => {
  //   setShowAll(!showAll);
  // };

  useEffect(() => {
    const newFilterData = data.filter(
      (t) => t.blog_category == categoryChecker
    );
    setfilteredData(newFilterData);
  }, [categoryChecker]);


  
  const customStyle = {
 fontSize:'18px'
  };
  function stripHTML(html) {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {data2[0] && (
        <Helmet>
          <title>{data2[0]?.meta_title}</title>

          <meta name="description" content={data2[0]?.meta_description} />
          <meta name="keywords" content={JSON.parse(data2[0]?.meta_keywords)} />
          <link rel="canonical" href={`${WebsiteUrl}`} />
          {/* <link
              rel="canonical"
              href={`${WebsiteUrl}`}
            /> */}
          <meta name="robots" content="index,follow" />
          <meta property="og:title" content={data2[0]?.meta_title} />
          <meta
            property="og:description"
            content={data2[0]?.meta_description}
          />
          <meta
            property="og:image"
            content={`${global.imageURL}/nodeassets/${data2[0]?.meta_img}`}
          />
          {/* <meta property="og:url" content={`${WebsiteUrl}`} /> */}
          <meta property="og:url" content={`${global.backendUrl}`} />
          <meta property="og:type" content="website" />
          <meta name="twitter:card" content={data2[0]?.meta_title} />
          <meta name="twitter:title" content={data2[0]?.meta_title} />
          <meta
            name="twitter:description"
            content={data2[0]?.meta_description}
          />

          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <link rel="favicon" href="/favicon.ico" />
          <meta
            name="twitter:image"
            content={`${global.imageURL}/nodeassets/${data2[0]?.meta_img}`}
          />
        </Helmet>
      )}
      <PageTitle sub="Blog" title="Blogs" />

      <section className="tf-blog">
        <div className="tf-container">
          <div className="row justify-content-center">
            <div className="col-md-10">
            {loading ? (  
                <div className="loading-container">
                  <span className='loader'></span>
                </div>
              ) : (
              <div className="main-content">
                {filteredData
                  .slice(indexOfFirstPost, indexOfLastPost)
                  .map((idx) => (
                    <article key={idx.id} className="tf-blog-item style-2">
                      <div className="image">
                        <Link to={`/blog-details-v1/${idx.blog_slug}`}>
                          <img
                            src={`${global.imageURL}/nodeassets/${idx.blog_img}`}
                            alt="ANTS"
                          />
                        </Link>
                      </div>

                      <div className="title">
                        <h5>
                          <Link to={`/blog-details-v1/${idx.blog_slug}`}>
                            {idx.blog_title}
                            {console.log(idx.blog_slug)}
                          </Link>
                        </h5>
                        <Link
                          to={`/blog-details-v1/${idx.blog_slug}`}
                          className="tf-button style-1"
                        >
                          <span>{idx.blog_category}</span>
                        </Link>
                      </div>
                   
                       <p style={customStyle}>
    {truncate(stripHTML(idx.blog_desc), { length: 230 })}
  </p>
                      {/* <p className="content">{idx.text}</p> */}

                      <div className="meta">
                        {/* <span className="admin">
                          {' '}
                          <i className="fal fa-user"></i>
                          Jhon Doe
                        </span> */}
                        {/* <span className="date">
                          <i className="far fa-clock"></i>
                          {idx.blog_postingDate}
                        </span> */}
                      </div>
                    </article>
                  ))}

                <ul className="pagination">
                  {Array.from({
                    length: Math.ceil(data.length / postsPerPage),
                  }).map((_, index) => (
                    <li key={index} className="page-item">
                      <button
                        onClick={() => paginate(index + 1)}
                        className="page-link"
                      >
                        {index + 1}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
              )}
            </div>
            {/* <div className="col-md-4">
              <div className="side-bar">
                <div className="widget widget-category">
                  <h6 className="widget-title">Category</h6>
                  <ul>
                    {data3?.map((C, i) => (
                      <li
                        onClick={() => setcategoryChecker(C.category_name)}
                        style={{ cursor: 'pointer' }}
                      >
                        <a>{C?.category_name}</a>{' '}
                        <span>
                          {
                            data.filter(
                              (t) => t.blog_category == C.category_name
                            ).length
                          }
                        </span>
                      </li>
                    ))} */}
                    {/* <li>
                      <Link to="#">NFT Creators</Link> <span>23</span>
                    </li>
                    <li>
                      <Link to="#">Rare Products</Link> <span>12</span>
                    </li>
                    <li>
                      <Link to="#">Rare Vedios</Link> <span>76</span>
                    </li>
                    <li>
                      <Link to="#">Digital Arts</Link> <span>123</span>
                    </li>
                    <li>
                      <Link to="#">App Music NFT’s</Link> <span>64</span>
                    </li>
                    <li>
                      <Link to="#">Application</Link> <span>108</span>
                    </li>
                    <li>
                      <Link to="#">Art</Link> <span>67</span>
                    </li> */}
                  {/* </ul>
                </div> */}
                {/* <div className="widget widget-recent-post">
                  <h6 className="widget-title">Recent Post</h6>
                  <ul>
                    {dataRecent.map((idx) => (
                      <li key={idx.id}>
                        <div className="post-img">
                          <img src={idx.img} alt="Post New" />
                        </div>
                        <div className="post-content">
                          <h6 className="title">
                            <Link to="/blog-details-v2">{idx.title}</Link>
                          </h6>
                          <Link to="/blog-details-v2" className="post-category">
                            {idx.cate}
                          </Link>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="widget widget-tag ">
                  <h6 className="widget-title">Popular Tag</h6>
                  <ul>
                    <li>
                      <Link to="#">Bitcoin</Link>
                    </li>
                    <li>
                      <Link to="#">NFT</Link>
                    </li>
                    <li>
                      <Link to="#">Bids</Link>
                    </li>
                    <li>
                      <Link to="#">Digital</Link>
                    </li>
                    <li>
                      <Link to="#">Arts</Link>
                    </li>
                    <li>
                      <Link to="#">Maketplace</Link>
                    </li>
                    <li>
                      <Link to="#">Token</Link>
                    </li>
                    <li>
                      <Link to="#">Wallet</Link>
                    </li>
                    <li>
                      <Link to="#">Crypto</Link>
                    </li>
                  </ul>
                </div> */}
              {/* </div>
            </div> */}
          </div>
        </div>
      </section>
    </>
  );
}

export default Blog02;
{
  /* <div className="load-more style-2">
                    <button className="tf-button loadmore" onClick={toggleShowAll}>
                      {
                        showAll ? 'Load Less' : 'Load more'
                      }
                    </button>
                  </div> */
}
