import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import Banner01 from '../components/banner/Banner01';
import Category from '../components/category/Category';
import Collection from '../components/collection/Collection';
import Create from '../components/create/Create';
import Explore from '../components/explore/Explore';
import HotPick from '../components/hotpick/HotPick';
import LiveAutions from '../components/liveautions/LiveAuctions';
import TopSeller from '../components/topseller/TopSeller';
import TopSeller5 from '../components/topseller/TopSeller5';

function Home01(props) {
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);
  const [data4, setData4] = useState([]);
  const [data5, setData5] = useState([]);
  const [data6, setData6] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [titles, setTitles] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responses = await Promise.all([
          axios.get(`${global.backendUrl}/homePageSco/get`),
          axios.get(`${global.backendUrl}/antspartnerstitle`),
          axios.get(`${global.backendUrl}/ourantspartners`),
          axios.get(`${global.backendUrl}/project/get`),
          axios.get(`${global.backendUrl}/stats/get`),
          axios.get(`${global.backendUrl}/projectCate/get`),
          axios.get(`${global.backendUrl}/signup/get`),
        ]);

        setData(responses[0].data[0]);
        setTitles(responses[1].data);
        setData2(responses[2].data);
        setData3(responses[3].data);
        setData4(responses[4].data);
        setData5(responses[5].data);
        setData6(responses[6].data);

        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  if (isLoading) {
    return (
      <div className="loading-container">
        <div className="loader"></div>
      </div>
    );
  }

  return (
    <div className="home-1">
      {data && (
        <Helmet>
          <title>{data?.meta_title}</title>
          <meta name="description" content={data?.meta_description} />
          <meta name="keywords" content={JSON.parse(data?.meta_keywords)} />
          <meta name="robots" content="index,follow" />
          <meta property="og:title" content={data?.meta_title} />
          <meta property="og:description" content={data?.meta_description} />
          <meta
            property="og:image"
            content={`${global.imageURL}/nodeassets/${data?.meta_img}`}
          />
          <meta property="og:type" content="website" />
          <meta name="twitter:card" content={data?.meta_title} />
          <meta name="twitter:title" content={data?.meta_title} />
          <meta name="twitter:description" content={data?.meta_description} />
          <meta
            name="twitter:image"
            content={`${global.imageURL}/nodeassets/${data?.meta_img}`}
          />
        </Helmet>
      )}
      <div id="page">
        <Banner01 />
        <Create data={data4} />
        <Category data={data5} />
        <LiveAutions data={data3} />
        <HotPick data={data5} data2={data3} data3={data6} />
        <Collection data={data6} />
        <TopSeller data={data5} data2={data3} data3={data6} />
        <Explore data={data5} data2={data3} data3={data6} />
        <TopSeller5 data={data2} titles={titles} />
      </div>
    </div>
  );
}

export default Home01;
