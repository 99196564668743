import { useEffect, useState } from "react";
import { getStaking } from "../api/Api";

export const usePools=()=>{
    const [dataAPI, setDataAPI] = useState([]);
    const [loading, setLoading] = useState(true);  

    const getData = async () => {
      try {
        setLoading(true);  
        const res = await getStaking();
        setDataAPI([...res.data]);
      } catch (error) {
        console.error("Error fetching staking data:", error);
      } finally {
        setLoading(false);  
      }
    };

    useEffect(() => {
        
        getData()
      }, []);
      return {
        dataAPI,
        getData,
        loading,
      }
}